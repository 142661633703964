.servicesFamily {
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsFamily {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsFamily {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsFamily {
    grid-template-columns: 1fr;
  }
}

/* .cardFamily {
  background-color: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
} */
