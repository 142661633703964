.servicesDeportation {
  height: auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsDeportation {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsDeportation {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsDeportation {
    grid-template-columns: 1fr;
  }
}
