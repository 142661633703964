.servicesGC {
  height: auto;
  display: grid;
  /* grid-template-columns: 0.125fr 1fr 0.125fr; */
  grid-template-rows: 0.1fr auto 0.1fr;
  margin: 0;
  grid-column: 1 / -1;
}

.cardsGC {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsGC {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsGC {
    grid-template-columns: 1fr;
  }
}

/* .cardGC {
  background-color: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
} */
