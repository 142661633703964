.servicesEmployment {
  height: auto;
  display: grid;
  grid-template-columns: 1fr ;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsEmployment {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsEmployment {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsEmployment {
    grid-template-columns: 1fr;
  }
}
