.legalNews {
  display: grid;
  grid-template-rows: auto;
  height: auto;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin-top: 180px;
    margin-bottom: 24px;
    max-width: 900px;
    width: 90vw;

    &--title {
      font-weight: 700;
      color: #8c001acc;
    }

    &--underline {
      height: 4px;
      background-color: #8c001acc;
      width: 400px;
    }
    &--news {
      margin: 20px;
      font-weight: 500;
      color: #8c001acc;
      display: grid;
      // object-fit: contain;
      text-align: left;
    }
  }
}

.newsTitleHeader {
  text-align: justify;
  vertical-align: bottom;
  padding-left: 3%;
  margin-bottom: 0%;
}

.cardNews {
  overflow-y: auto;
}

.newsTitle {
  padding-top: 3%;
  margin-bottom: 0%;
  font-weight: 400;
  text-align: left;
  color: #8c001acc;
  border-bottom: 2px solid #8c001acc;
  display: inline-block;
  position: relative;
  bottom: 0;
}

.newsDetail {
  text-align: left;
}

.dateNews {
  font-size: 12px;
  text-align: left;
  padding-left: 3%;
  color: #8c001acc;
  display: inline;
}

.newsUrl {
  font-size: 12px;
  color: #8c001acc;
}

.carousel .control-dots {
  margin: 0px !important;
}

.carouselContainerNews {
  grid-column: 1 / -1;
  width: 90%;
  justify-content: center;
  // padding-left: 25%;
  // padding-right: 25%;
}

.linkSvg {
  display: inline !important;
  height: 12px;
  width: 12px !important;
  margin-top: 6px;
}

.slide {
  width: 60% !important;
  // min-width: 80% !important;
}

@media only screen and (max-width: 926px) {
  .carouselContainerNews {
    width: 60%;
  }
}

@media only screen and (max-width: 800px) {
  .carouselContainerNews {
    width: 60%;
  }
}
