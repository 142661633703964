.header {
  height: 100vh;
  background-image: url(https://imgur.com/GrygL3z.png);
}

.parallax {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}

.header-content {
  display: grid;
}

.header-image {
  height: 500px;
  width: 400px;
}

.header-title {
  color: white;
  grid-row: 5 / span 8;
  grid-column: 3 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-name {
  font-weight: 700;
  font-size: 60px;
  grid-column: 2 / span 3;
  grid-row: 3 / span 5;
  align-self: center;
  color: white;
  text-align: center;
  opacity: 1;
}

.header-tagline {
  font-size: 30px;
  font-weight: 500;
  align-self: center;
  color: white;
  text-align: center;
  opacity: 1;
}

.header-gradient {
  background-color: rgb(255, 0, 0, 0.55);
  grid-row: 1 / -1;
  grid-column: 1 / 6;
  width: 96%;
  /* opacity: 0.55; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
}

.header-intro {
  grid-row: 5 / 8;
  grid-column: 2 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.line {
  align-self: center;
  width: 300px !important;
}

/*MEDIA QUERIES FOR RESPONSIVENESS */

@media only screen and (max-width: 1300px) {
  .header-name {
    font-size: 50px;
  }

  .header-tagline {
    font-size: 35px;
  }

  .line {
    width: 250px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .header-intro {
    grid-column: 1 / -1;
  }
  /* .header-gradient {
    margin: 30px;
    margin-top: 110px;
  } */
  .parallax {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .header-name {
    font-size: 34px;
  }

  .header-tagline {
    font-size: 15px;
  }

  .line {
    width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .header-name {
    font-size: 36px;
  }

  .header-tagline {
    font-size: 15px;
  }

  .line {
    width: 150px;
  }

  .header-gradient {
    grid-row: 1 / -1;
    grid-column: 1 / 6;
    margin: 5%;
    margin-top: 110px;
  }
}

@media only screen and (max-width: 450px) {
  .header-name {
    font-size: 20px;
  }

  .header-tagline {
    font-size: 14px;
  }

  .line {
    width: 100px;
  }

  .header-gradient {
    grid-row: 1 / -1;
    grid-column: 1 / 6;
  }
}
