.practiceareas {
	display: grid;
	grid-template-columns: 4fr 6fr;
	overflow: auto;
}

.headerPane {
	background-color: rgba(205, 0, 51, 0.2);
	display: grid;
	grid-template-rows: 3fr 3fr 10fr;
	grid-template-columns: 30fr 70fr;
}

.headerTxt {
	grid-row: 2 / 3;
	grid-column: 2 / 3;
	align-self: flex-end;
	/* justify-self: right; */
	margin: 0px 10px 0px 0px;
	font-size: 3.2em;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: Bold;
}

.subTextpa {
	grid-row: 3 / 4;
	grid-column: 2 / 3;
	align-self: flex-start;
	/* justify-self: left; */
	margin: 0;
	font-size: 2em;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: normal;
}

.areaPane {
	background-color: rgba(247, 247, 247, 0.4);
}

.areaItems {
	display: grid;
	grid-template-columns: 1fr 10fr;
}

.listItems {
	/* text-align: center; */
	align-self: center;
	justify-self: start;
}
.areaList {
	display: grid;
	list-style-type: none;
	/* grid-row: 1 / -1; */
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-size: 20px;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: Bold;
	padding: 0;
	max-height: 100%;
}

.gavel {
	height: 35px;
	width: 35px;
	justify-self: center;
	align-self: center;
}

.practiceAreaDesc {
	font-size: 13px;
	grid-column: 2 / 3;
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	/* display: grid;
  grid-template-columns: 10% 90%; */
	color: black;
}

.bulletPoint {
	display: none;
}

@media screen and (max-width: 726px) {
	.practiceareas {
		grid-template-columns: 1fr;
		grid-template-rows: 0.5fr 2fr;
		overflow: auto;
	}
	.headerPane {
		grid-template-columns: 1fr;
		align-items: center;
		justify-items: center;
	}
	.headerTxt {
		font-size: 2.2em;
		grid-column: 1 / 3;
		justify-items: center;
	}
	.subTextpa {
		grid-column: 1 / 2;
	}
}
@media screen and (max-width: 396px) {
	.practiceareas {
		overflow: auto;
	}
}
@media only screen and (max-height: 568px) {
	.practiceareas {
		overflow: visible;
		grid-template-columns: 0.87fr;
	}
	.headerTxt {
		font-size: 2em;
	}
}
