.links {
  display: inline;
  cursor: pointer;
}

.links:hover {
  color: #8c001acc;
}

.links::after {
  content: " / ";
}

.links:last-of-type:after {
  content: "";
}

.navigation {
  align-content: left;
  grid-column: 1 / -1;
}
