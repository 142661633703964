.login {
  display: grid;
  grid-template-rows: 10vh auto 15vh;
}

.loginSection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loginBox {
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-end;
  //   background-color: red;
  height: 400px;
  width: 300px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  margin-top: 10%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  height: 60%;
  align-items: center;
}

.upperLoginBackground {
  height: 40%;
  width: 100%;
  background-image: linear-gradient(#8c001acc, #8c001acc),
    url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  //   clip-path: polygon(0% 0%, 0% 70%, 100% 100%, 100% 0%);
  background-blend-mode: hard-light;
}

.emailInput {
  width: 200px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: inherit;
  color: inherit;
  padding: 15px 20px;
  border-radius: 2px;
  background-color: rgba(white, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
  &:focus {
    outline: none;
    box-shadow: 0 10px 20px rgba(black, 0.1);
    border-bottom: 3px solid green;
  }
  &:focus:invalid {
    border-bottom: 3px solid red;
  }
}

.pwInput {
  width: 200px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: inherit;
  color: inherit;
  padding: 15px 20px;
  border-radius: 2px;
  background-color: rgba(white, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
  &:focus {
    outline: none;
    box-shadow: 0 10px 20px rgba(black, 0.1);
    border-bottom: 3px solid green;
  }
  &:focus:invalid {
    border-bottom: 3px solid red;
  }
}

.loginButton {
  width: 200px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 30px;
  color: white;
  background-color: #8c001acc;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }

  &__loginButton {
    width: 120px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    padding: 0 30px;
    color: white;
    background-color: #8c001acc;
    grid-row: 1 /2;
    grid-column: 4 / 5;
    justify-self: end;
    // align-self: end;
  }
}

.legalNewsInput {
  grid-row: 2 / 4;
  grid-column: 1 / -1;
  width: 90%;
  vertical-align: text-top;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  line-height: 1.5em;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  line-height: 1.5em;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  line-height: 1.5em;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  line-height: 1.5em;
}

.submitButton {
  grid-row: 4 / 5;
  grid-column: 4 / 5;
  height: 30px;
  justify-self: end;
  width: 120px;
  height: 30px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 30px;
  color: white;
  background-color: #8c001acc;
  margin-top: 10px;
}

.titleInput {
  height: 30px;
  width: 40%;
  margin: 0px 10px 10px 0px;
}

.urlInput {
  height: 30px;
  width: 40%;
  margin: 10px;
}

.loginHeader {
  display: flex;
  align-items: center;
}
.loginHeader h1 {
  margin: 10px;
}

.logoutButton {
  width: 150px;
  height: 40px;
  margin: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 30px;
  color: white;
  background-color: #8c001acc;
}

.legalNewsDropdown{
  margin-left: 20px;
  align-self: center;
}