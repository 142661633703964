.servicesMain {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: auto-fit;
  grid-gap: 20px;
  margin: 120px 0 40px 0;
  transition: all 0.2s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1693px) {
  .servicesMain {
    grid-template-columns: 1fr;
  }
}
