.servicesAsylum {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto-fit;
  grid-gap: 20px;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 1693px) {
  .servicesAsylum {
    grid-template-columns: 1fr;
  }
}
