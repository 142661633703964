.navbar {
  background-color: transparent;
}
.top-nav-collapse {
  background-color: #8c001acc;
}
@media only screen and (max-width: 768px) {
  .navbar {
    background-color: #8c001acc;
  }
}

.NavLink {
  outline: none;
  color: #8c001acc;
  font-size: 20px;
  transition: all 0.2s ease;
  transition-property: transform, opacity, color, border-color, background-color;
}

.logo {
  height: 35px;
  width: 375px;
}

.navbtn {
  color: #8c001acc;
  font-weight: 1000;
  font-size: 10px;
}

.navbar-toggler-icon {
  background-image: url(../images/iconfinder_menu-alt_134216.svg) !important;
  color: red !important;
}

.ml-auto,
.mx-auto {
  margin-left: 5% !important;
}

.navbar.scrolling-navbar {
  z-index: 1 !important;
}

@media only screen and (min-width: 992px) {
  .paDropDown2 {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .paDropDown1 {
    display: none;
  }
}

@media only screen and (max-width: 1250px) {
  .navbtn {
    font-size: 10px;
  }
  .logo {
    height: 25px;
    width: 275px;
  }
}

@media only screen and (max-width: 1676px) {
}
@media only screen and (max-width: 1000px) {
  .navbtn {
    font-size: 9px;
  }

  .logo {
    height: 25px;
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .navbtn {
    font-size: 11px;
  }
}
@media only screen and (max-width: 600px) {
  .logo {
    height: 25px;
    width: 210px;
  }
}
@media only screen and (max-width: 350px) {
  .logo {
    height: 25px;
    width: 170px;
  }
}

.dropdown-toggle:after {
  vertical-align: 0.07em;
}

.navbar .dropdown-menu a {
  padding: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
}
