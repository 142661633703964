@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  height: 100vh;
  background-image: url(https://imgur.com/GrygL3z.png);
}

.parallax {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}

.header-content {
  display: grid;
}

.header-image {
  height: 500px;
  width: 400px;
}

.header-title {
  color: white;
  grid-row: 5 / span 8;
  grid-column: 3 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-name {
  font-weight: 700;
  font-size: 60px;
  grid-column: 2 / span 3;
  grid-row: 3 / span 5;
  align-self: center;
  color: white;
  text-align: center;
  opacity: 1;
}

.header-tagline {
  font-size: 30px;
  font-weight: 500;
  align-self: center;
  color: white;
  text-align: center;
  opacity: 1;
}

.header-gradient {
  background-color: rgb(255, 0, 0, 0.55);
  grid-row: 1 / -1;
  grid-column: 1 / 6;
  width: 96%;
  /* opacity: 0.55; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
}

.header-intro {
  grid-row: 5 / 8;
  grid-column: 2 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.line {
  align-self: center;
  width: 300px !important;
}

/*MEDIA QUERIES FOR RESPONSIVENESS */

@media only screen and (max-width: 1300px) {
  .header-name {
    font-size: 50px;
  }

  .header-tagline {
    font-size: 35px;
  }

  .line {
    width: 250px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .header-intro {
    grid-column: 1 / -1;
  }
  /* .header-gradient {
    margin: 30px;
    margin-top: 110px;
  } */
  .parallax {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .header-name {
    font-size: 34px;
  }

  .header-tagline {
    font-size: 15px;
  }

  .line {
    width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .header-name {
    font-size: 36px;
  }

  .header-tagline {
    font-size: 15px;
  }

  .line {
    width: 150px;
  }

  .header-gradient {
    grid-row: 1 / -1;
    grid-column: 1 / 6;
    margin: 5%;
    margin-top: 110px;
  }
}

@media only screen and (max-width: 450px) {
  .header-name {
    font-size: 20px;
  }

  .header-tagline {
    font-size: 14px;
  }

  .line {
    width: 100px;
  }

  .header-gradient {
    grid-row: 1 / -1;
    grid-column: 1 / 6;
  }
}

.practiceareas {
	display: grid;
	grid-template-columns: 4fr 6fr;
	overflow: auto;
}

.headerPane {
	background-color: rgba(205, 0, 51, 0.2);
	display: grid;
	grid-template-rows: 3fr 3fr 10fr;
	grid-template-columns: 30fr 70fr;
}

.headerTxt {
	grid-row: 2 / 3;
	grid-column: 2 / 3;
	align-self: flex-end;
	/* justify-self: right; */
	margin: 0px 10px 0px 0px;
	font-size: 3.2em;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: Bold;
}

.subTextpa {
	grid-row: 3 / 4;
	grid-column: 2 / 3;
	align-self: flex-start;
	/* justify-self: left; */
	margin: 0;
	font-size: 2em;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: normal;
}

.areaPane {
	background-color: rgba(247, 247, 247, 0.4);
}

.areaItems {
	display: grid;
	grid-template-columns: 1fr 10fr;
}

.listItems {
	/* text-align: center; */
	align-self: center;
	justify-self: start;
}
.areaList {
	display: grid;
	list-style-type: none;
	/* grid-row: 1 / -1; */
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-size: 20px;
	color: rgba(103, 0, 26, 1);
	font-family: "Libre Baskerville", serif;
	font-weight: Bold;
	padding: 0;
	max-height: 100%;
}

.gavel {
	height: 35px;
	width: 35px;
	justify-self: center;
	align-self: center;
}

.practiceAreaDesc {
	font-size: 13px;
	grid-column: 2 / 3;
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	/* display: grid;
  grid-template-columns: 10% 90%; */
	color: black;
}

.bulletPoint {
	display: none;
}

@media screen and (max-width: 726px) {
	.practiceareas {
		grid-template-columns: 1fr;
		grid-template-rows: 0.5fr 2fr;
		overflow: auto;
	}
	.headerPane {
		grid-template-columns: 1fr;
		align-items: center;
		justify-items: center;
	}
	.headerTxt {
		font-size: 2.2em;
		grid-column: 1 / 3;
		justify-items: center;
	}
	.subTextpa {
		grid-column: 1 / 2;
	}
}
@media screen and (max-width: 396px) {
	.practiceareas {
		overflow: auto;
	}
}
@media only screen and (max-height: 568px) {
	.practiceareas {
		overflow: visible;
		grid-template-columns: 0.87fr;
	}
	.headerTxt {
		font-size: 2em;
	}
}

.award-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgba(205, 0, 51, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%23ffffff' fill-opacity='0.36' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.award-image {
  height: 140px;
  width: 140px;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1400px) {
  .award-row {
    /* width: 50%; */
    display: grid;
    justify-content: center;
  }
  .avvoProfile {
    justify-self: center;
  }
}

@font-face {
  font-family: "Circular-Bold";
  src: url("https://assets.avvo.com/avvo-ui/0.20.5/fonts/avvo_ui/circular/circular_bold.woff")
    format("woff");
}
.avvo-badge .st0 {
  fill: #153860 !important;
}
.avvo-badge .st1 {
  fill: #010101 !important;
}
.avvo-badge .st2 {
  fill: #15467a !important;
}
.avvo-badge .st3 {
  fill: #ffffff !important;
}
.avvo-badge .st4 {
  fill: none !important;
}
.avvo-badge .st5 {
  fill: #f89838 !important;
}
.avvo-badge .st6 {
  fill: #ed514c !important;
}
.avvo-badge .st7 {
  font-family: "Circular-Bold", Avenir Next, Avenir, Century Gothic, sans-serif !important;
  font-weight: bold !important;
}
.avvo-badge .st8 {
  font-size: 20px !important;
}
.avvo-badge .st9 {
  letter-spacing: -1px !important;
}
.avvo-badge .st10 {
  font-family: "Circular-Bold", Avenir Next, Avenir, Century Gothic, sans-serif !important;
  font-size: 14px;
}
.avvo-badge .st11 {
  font-size: 65px !important;
}
.avvo-badge .st12 {
  text-anchor: middle !important;
}
.avvo-badge .st13 {
  fill: #969696 !important;
}
.avvo-badge .st14 {
  display: none !important;
}

.avvo-badge-style {
  enable-background: new 0 0 280 220;
  height: 250px;
  width: 250px;
  margin-top: 30px;
}

.st-12-style {
  font-size: 14px !important;
}

.avvoProfile {
  align-self: center;
  margin: 0 10px 0 20px;
}

.award1 {
  width: 100%;
}

@media only screen and (max-height: 568px) {
  .award-row {
    grid-template-columns: 0.87fr;
  }
}

.profile {
  background-image: -webkit-linear-gradient(#8c001acc, #8c001acc),
    url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-image: linear-gradient(#8c001acc, #8c001acc),
    url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.profile-title {
  margin-top: 20px;
  color: white;
  font-weight: 700;
}

.profile-content {
  height: 100%;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
}

.profile-image {
  height: 470px;
  width: 400px;
  grid-row: 2 / span 12;
  grid-column: 9 / 12;
  margin-top: 11px;
}

.profile-text {
  font-size: 20px;
  font-weight: 500;
  color: white;
  grid-row: 2 / span 10;
  grid-column: 2 / 8;
  margin-left: 40px;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .profile-text {
    font-size: 16px;
  }

  .profile-image {
    height: 400px;
    width: 330px;
    margin-top: 32px;
  }
}
@media only screen and (max-width: 1300px) {
  .profile-text {
    font-size: 14px;
  }

  .profile-image {
    height: 350px;
    width: 300px;
    margin-top: 26px;
  }
}
@media only screen and (max-width: 1000px) {
  .profile-text {
    font-size: 16px;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-content: center;
    text-align: center;
    word-wrap: normal;
    margin: 0;
    padding: 10px;
  }

  .profile-title {
    font-size: 32px;
  }

  .profile-content {
    margin: 0 auto;
  }

  .profile-image {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .profile-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .profile-text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .profile-text {
    font-size: 12px;
  }
}

@media only screen and (max-height: 568px) {
  .profile-title {
    font-size: 25px;
  }
  .profile-content {
    grid-template-columns: 0.87fr;
  }
}

.contactUs {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 20% 80%;
  max-height: 70vh;
  background-image: url("https://images.pexels.com/photos/1569012/pexels-photo-1569012.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}

.contactUs-headerPane {
  background-color: rgba(205, 0, 51, 0.9);
  display: grid;
  grid-row: 1 / -1;
  grid-template-rows: 15fr 10fr 10fr 20fr 20fr 25fr;
  grid-template-columns: 30fr 70fr;
  max-height: 70vh;
}
.contactUs-headerTxt {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  align-self: flex-end;
  /* justify-self: right; */
  margin: 0px 10px 0px 0px;
  font-size: 3.2em;
  color: white;
  font-family: "Libre Baskerville", serif;
  font-weight: Bold;
}

.contactUs-contactName {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: flex-start;
  margin: 0;
  font-size: 2em;
  color: white;
  font-family: "Libre Baskerville", serif;
  font-weight: normal;
}
.contact-areaPane {
  background-color: rgba(205, 0, 51, 0.75);
  display: grid;
  max-height: 70vh;
  align-content: center;
  padding-left: 20px;
  color: white;
  background-blend-mode: color;
  grid-row: 1 / -1;
}

.contact-areaPane__info {
  display: flex;
  flex-direction: column;
}

.contact-address__title {
  padding-bottom: 40px;
  font-weight: 350;
  font-size: 24px;
}
.contact-info__address {
  font-weight: 500;
}
.contact-info__email {
  font-weight: 500;
}
.contact-info__tel {
  font-weight: 500;
}

.bold {
  font-weight: 900;
}

.contact-form__forms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  height: 155px;
  width: 90%;
  padding-top: 20px;
}
textarea {
  resize: none;
}

.contact-form__forms-message {
  grid-column: 2 / 3;
  grid-row: 1 / span 3;
}

.contact-form__forms-submit {
  width: 90px;
  grid-column: 2 / 3;
  justify-self: end;
  background-color: rgb(66, 0, 0, 0.8);
  border: solid rgb(66, 0, 0, 1);
  cursor: pointer !important;
  color: white;
}

/*Responsive Design */
@media screen and (max-width: 1300px) {
  .contactUs-headerTxt {
    font-size: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .contactUs-headerPane {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  .contact-areaPane {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }
  .contactUs-contactName {
    display: none;
  }
  .contactUs-headerTxt {
    align-self: center;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-self: center;
  }
}

.homeBar {
  height: 14vh;
  height: 9vh;
  background-color: rgba(205, 0, 51, 1);
}

.homeBar-Link {
  width: 100%;
  height: 100%;
  display: flex;
}

.homeBar-Link__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: white;
}

.homeBar-Link__content-image {
  align-self: center;
  justify-self: center;
}
.homeBar-Link__content-text {
  margin-top: -10px;
  align-self: center;
  justify-self: center;
}

.callNow {
  color: #8c001acc;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: auto;
  position: fixed;
  left: 84vw;
  top: 3.45vh;
  z-index: 2; }

.phoneSVG {
  height: 25px;
  width: 25px;
  text-align: center;
  margin-right: 5px; }

.callNowNumber {
  display: flex;
  align-self: center;
  font-size: 16px; }

.callNowText {
  font-size: 16px;
  margin-right: 5px;
  align-self: center;
  font-weight: 900; }

@media only screen and (max-width: 1166px) {
  .callNow {
    left: 87.5vw; }
  .callNowNumber {
    font-size: 12px; }
  .phoneSVG {
    height: 17px;
    width: 17px; } }

@media only screen and (max-width: 991px) {
  .callNow {
    left: 50vw;
    top: 3vh;
    font-size: 16px; }
  .callNowNumber {
    font-size: 16px; }
  .phoneSVG {
    height: 25px;
    width: 25px; } }

@media only screen and (max-width: 499px) {
  .callNow {
    display: none; } }

.navbar {
  background-color: transparent;
}
.top-nav-collapse {
  background-color: #8c001acc;
}
@media only screen and (max-width: 768px) {
  .navbar {
    background-color: #8c001acc;
  }
}

.NavLink {
  outline: none;
  color: #8c001acc;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition-property: opacity, color, border-color, background-color, -webkit-transform;
  transition-property: opacity, color, border-color, background-color, -webkit-transform;
  transition-property: transform, opacity, color, border-color, background-color;
  transition-property: transform, opacity, color, border-color, background-color, -webkit-transform;
}

.logo {
  height: 35px;
  width: 375px;
}

.navbtn {
  color: #8c001acc;
  font-weight: 1000;
  font-size: 10px;
}

.navbar-toggler-icon {
  background-image: url(/static/media/iconfinder_menu-alt_134216.3a439590.svg) !important;
  color: red !important;
}

.ml-auto,
.mx-auto {
  margin-left: 5% !important;
}

.navbar.scrolling-navbar {
  z-index: 1 !important;
}

@media only screen and (min-width: 992px) {
  .paDropDown2 {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .paDropDown1 {
    display: none;
  }
}

@media only screen and (max-width: 1250px) {
  .navbtn {
    font-size: 10px;
  }
  .logo {
    height: 25px;
    width: 275px;
  }
}

@media only screen and (max-width: 1676px) {
}
@media only screen and (max-width: 1000px) {
  .navbtn {
    font-size: 9px;
  }

  .logo {
    height: 25px;
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .navbtn {
    font-size: 11px;
  }
}
@media only screen and (max-width: 600px) {
  .logo {
    height: 25px;
    width: 210px;
  }
}
@media only screen and (max-width: 350px) {
  .logo {
    height: 25px;
    width: 170px;
  }
}

.dropdown-toggle:after {
  vertical-align: 0.07em;
}

.navbar .dropdown-menu a {
  padding: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media screen and (max-width: 610px) {
  .footer .centerLogo {
    justify-content: center;
  }

  #copyright {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 396px) {
  #copyright {
    font-size: 0.55em;
  }
}
.footer {
  /* background-color: white; */
  display: grid;
  grid-template-columns: 20fr 60fr 20fr;
  height: 140px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2367001a' fill-opacity='0.15' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.contact {
  display: grid;
  grid-template-columns: 20fr 10fr 40fr 30fr;
  grid-template-rows: 20fr 14fr 14fr 14fr 14fr 20fr;
  padding-left: 2%;
}

.centerLogo {
  /* background-image: url("../images//logos/LOGO-01.png"); */
  grid-column: 2 / 2;
  display: grid;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
}

.logoTag {
  display: grid;
  background-image: url(/static/media/LOGO-01.e3ff1e27.png);
  background-size: 100% 100%;
  font-size: 0.6em;
  font-family: "Libre Baskerville", serif;
  font-weight: normal;
  height: 100%;
  width: 100%;
  align-content: flex-end;
  color: rgba(103, 0, 26, 1);
}

#copyright {
  vertical-align: bottom;
  color: rgba(103, 0, 26, 1);
}

.paraTransition {
  height: 100%;
  width: 100%;
  background-image: -webkit-linear-gradient(left top, #fff, #8c001acc),
    url(https://images.pexels.com/photos/36369/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-image: linear-gradient(to right bottom, #fff, #8c001acc),
    url(https://images.pexels.com/photos/36369/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-blend-mode: soft-light;
}

.paraWrapper {
  height: 70vh;
}

.certificates-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(205, 0, 51, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%23ffffff' fill-opacity='0.36' fill-rule='evenodd'/%3E%3C/svg%3E"); }

.certificates-image-1 {
  height: 70px;
  width: 150px;
  margin-left: 20px;
  margin-right: 20px; }

.certificates-image-2 {
  height: 60px;
  width: 330px;
  margin-left: 20px;
  margin-right: 20px; }

.certificates-image-3 {
  height: 150px;
  width: 120px;
  margin-left: 20px;
  margin-right: 20px; }

.certificates-image-4 {
  height: 80px;
  width: 230px;
  margin-left: 20px;
  margin-right: 20px; }

@media only screen and (max-width: 800px) {
  .certificates-wrapper {
    width: 100%;
    display: flex;
    justify-content: center; } }

html,
body {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.App {
  display: grid;
  grid-template-rows: auto-fit;
  margin-left: 100px;
  margin-right: 100px;
}

@media only screen and (max-width: 1050px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}

.line {
  height: 3px;
  width: 350px;
  background: white;
  margin-bottom: 10px;
}

.line-medium {
  height: 3px;
  width: 200px;
  background: #8c001acc;
  margin-bottom: 10px;
}

.line-medium-white {
  height: 3px;
  width: 200px;
  background: white;
  margin-bottom: 10px;
}

.line-small {
  height: 3px;
  width: 100px;
  background: #8c001acc;
  margin-bottom: 10px;
}

.testimonials {
  grid-row: 4 / 5;
  justify-content: center;
  color: #3b1c1c;
  /* background-color: rgb(255, 0, 0, 0.11); */
  height: 70vh;
  width: 80vw;
  margin: auto;
}

.testimonials-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 700;
  padding-top: 30px;
}

.testimonials-cards {
  display: grid;
  padding-bottom: 30px;
  align-content: center;
  justify-items: center;
  width: 90%;
}

.cardTest {
  /* grid-column: auto-fit;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  width: 100%; 
  text-align: center; */
}

.cardTest .inner p {
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.cardTest .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.card-1-header {
  font-size: 16px;
  color: #3b1c1c;
  font-weight: 500;
}

.card-1-paragraph {
  text-align: center;
  color: #3b1c1c;
  width: 85%;
}

.eye-svg {
  height: 60px;
  width: 70px;
  margin-bottom: 10px;
  height: 70px;
  width: 70px;
  fill: #8c001acc;
}

.quote {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-arrow.control-prev::before {
  border-right: 8px solid #8c001acc !important;
}
.control-arrow.control-next::before {
  border-left: 8px solid #8c001acc !important;
}

.slide {
  margin: 0 20%;
}

.control-arrow:hover {
  background: #fff !important;
}

.dot {
  background: #8c001acc !important;
}

@media only screen and (max-height: 738px) {
  .testimonials {
    height: auto;
  }
}

@media only screen and (max-height: 568px) {
  .testimonials {
    height: 100vh;
    margin-right: auto;
    margin-left: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.servicesGC {
  height: auto;
  display: grid;
  /* grid-template-columns: 0.125fr 1fr 0.125fr; */
  grid-template-rows: 0.1fr auto 0.1fr;
  margin: 0;
  grid-column: 1 / -1;
}

.cardsGC {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsGC {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsGC {
    grid-template-columns: 1fr;
  }
}

/* .cardGC {
  background-color: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
} */

.servicesFamily {
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsFamily {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsFamily {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsFamily {
    grid-template-columns: 1fr;
  }
}

/* .cardFamily {
  background-color: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
} */

* {
  box-sizing: border-box; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.blog-slider1 {
  width: 70%;
  position: relative;
  max-width: 700px;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  -webkit-animation: fadeIn 0.5s ease-out;
          animation: fadeIn 0.5s ease-out;
  min-height: 500px;
  height: auto;
  margin: 60px auto; }
  @media screen and (max-width: 992px) {
    .blog-slider1 {
      max-width: 680px;
      height: 400px; } }
  @media screen and (max-width: 1246px) {
    .blog-slider1 {
      width: 95%; } }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider1 {
      height: 350px; } }
  .blog-slider1__item {
    display: flex;
    align-items: center;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .blog-slider1__item {
        flex-direction: column; } }
    .blog-slider1__item.swiper-slide-active .blog-slider__img img {
      opacity: 1;
      -webkit-transition-delay: 0.3s;
              transition-delay: 0.3s; }
    .blog-slider1__item.swiper-slide-active .blog-slider__content > * {
      opacity: 1;
      -webkit-transform: none;
              transform: none; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
        -webkit-transition-delay: 0.3s;
                transition-delay: 0.3s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
        -webkit-transition-delay: 0.4s;
                transition-delay: 0.4s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
        -webkit-transition-delay: 0.6s;
                transition-delay: 0.6s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
        -webkit-transition-delay: 0.7s;
                transition-delay: 0.7s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
        -webkit-transition-delay: 0.8s;
                transition-delay: 0.8s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
        -webkit-transition-delay: 0.9s;
                transition-delay: 0.9s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
        -webkit-transition-delay: 1s;
                transition-delay: 1s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
        -webkit-transition-delay: 1.1s;
                transition-delay: 1.1s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
        -webkit-transition-delay: 1.2s;
                transition-delay: 1.2s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
        -webkit-transition-delay: 1.3s;
                transition-delay: 1.3s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
        -webkit-transition-delay: 1.4s;
                transition-delay: 1.4s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
        -webkit-transition-delay: 1.5s;
                transition-delay: 1.5s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
        -webkit-transition-delay: 1.6s;
                transition-delay: 1.6s; }
      .blog-slider1__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
        -webkit-transition-delay: 1.7s;
                transition-delay: 1.7s; }
  .blog-slider1__img {
    flex-shrink: 0;
    height: 200px;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
    border-radius: 20px;
    overflow: hidden;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 90%; }
    .blog-slider1__img:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
      background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
      border-radius: 20px;
      opacity: 0.8; }
    .blog-slider1__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 20px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
    @media screen and (max-width: 576px) {
      .blog-slider1__img {
        width: 95%; } }
    @media screen and (max-height: 500px) and (min-width: 992px) {
      .blog-slider1__img {
        height: 270px; } }
  .blog-slider1__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px; }
    @media screen and (max-width: 576px) {
      .blog-slider1__content {
        padding: 0; } }
    .blog-slider1__content > * {
      -webkit-transform: translateY(25px);
              transform: translateY(25px);
      -webkit-transition: all 0.4s;
      transition: all 0.4s; }
  .blog-slider1__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500; }
  .blog-slider1__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px; }
  .blog-slider1__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em; }
  .blog-slider1__button {
    display: inline-flex;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    margin-bottom: 3px;
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px; }
    @media screen and (max-width: 576px) {
      .blog-slider1__button {
        width: 100%;
        margin-bottom: 3px; } }
  .blog-slider1__button1 {
    display: inline-flex;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    margin-left: 3px;
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px; }
    @media screen and (max-width: 576px) {
      .blog-slider1__button1 {
        width: 100%; } }
  .blog-slider1__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    text-align: center;
    bottom: auto !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center; }
    .blog-slider1__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 8px 0; }
      @media screen and (max-width: 768px) {
        .blog-slider1__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
          margin: 0 5px; } }
    .blog-slider1__pagination .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      display: block;
      border-radius: 10px;
      background: #062744;
      opacity: 0.2;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .blog-slider1__pagination .swiper-pagination-bullet-active {
        opacity: 1;
        background: #fd3838;
        height: 30px;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3); }
        @media screen and (max-width: 768px) {
          .blog-slider1__pagination .swiper-pagination-bullet-active {
            height: 11px;
            width: 30px; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.description {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: roboto;
  grid-column: 1 / -1;
  -webkit-animation: fadeIn 0.75s ease-out;
          animation: fadeIn 0.75s ease-out; }

.post {
  display: flex;
  width: 1000px; }

.post-left {
  width: 40%;
  background-color: #8c001acc;
  color: white;
  padding: 3em;
  position: relative; }

.post-left_title {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 700; }

.post-left_title_sub {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 0.25em; }

.post-left_border {
  border: 2px solid #b9b5be;
  width: 15%;
  margin-top: 1em; }

.post-left_author {
  position: absolute;
  bottom: 2em;
  display: flex;
  align-items: center; }
  .post-left_author img {
    width: 2.5em;
    margin-right: 1em;
    border: 1px solid #fefefe;
    border-radius: 50%; }

.post-right {
  width: 60%;
  background-color: #fefefe;
  color: #8c001acc; }

.post-right_body {
  line-height: 2;
  padding: 2em; }
  .post-right_body p {
    margin-top: 1em; }
    .post-right_body p span {
      font-size: 2em; }

.post-right_footer {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -4px 3px -2px rgba(0, 0, 0, 0.1);
  padding: 2em; }

.post-right_footer_date {
  text-transform: uppercase; }
  .post-right_footer_date span {
    font-weight: 700; }

.post-right_footer_pictos {
  font-size: 1.5em;
  margin-right: 1em; }
  .post-right_footer_pictos .fa-heart {
    color: #b9b5be;
    margin-right: 1em; }

@media screen and (max-width: 1693px) {
  .post {
    width: 80%; } }

@media screen and (max-width: 796px) {
  .post-left {
    display: none; }
  .post-right {
    width: 100%; }
  .post-right_body h3 {
    font-weight: 700; } }

.servicesEmployment {
  height: auto;
  display: grid;
  grid-template-columns: 1fr ;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsEmployment {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsEmployment {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsEmployment {
    grid-template-columns: 1fr;
  }
}

.servicesVisas {
  height: auto;
  display: grid;
  grid-template-columns: 0.125fr 1fr 0.125fr;
  grid-template-rows: 1fr;
  grid-column: 1 / -1;
  margin: 0;
}

.cardsVisas {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin: 0;
}

@media screen and (max-width: 1693px) {
  .cardsVisas {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsVisas {
    grid-template-columns: 1fr;
  }
}

/* .cardVisas {
  background-color: red;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
} */

.servicesDeportation {
  height: auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto-fit;
  grid-column: 1 / -1;
}

.cardsDeportation {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1693px) {
  .cardsDeportation {
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media screen and (max-width: 1246px) {
  .cardsDeportation {
    grid-template-columns: 1fr;
  }
}

* {
  box-sizing: border-box; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.blog-slider {
  width: 95%;
  position: relative;
  max-width: 700px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 350px;
  -webkit-animation: fadeIn 0.5s ease-out;
          animation: fadeIn 0.5s ease-out; }
  @media screen and (max-width: 992px) {
    .blog-slider {
      max-width: 680px;
      height: 400px; } }
  @media screen and (max-width: 800px) {
    .blog-slider {
      min-height: 500px;
      height: auto;
      margin: 60px auto; } }
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider {
      height: 350px; } }
  .blog-slider__item {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 800px) {
      .blog-slider__item {
        flex-direction: column; } }
    .blog-slider__item.swiper-slide-active .blog-slider__img img {
      opacity: 1;
      -webkit-transition-delay: 0.3s;
              transition-delay: 0.3s; }
    .blog-slider__item.swiper-slide-active .blog-slider__content > * {
      opacity: 1;
      -webkit-transform: none;
              transform: none; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
        -webkit-transition-delay: 0.3s;
                transition-delay: 0.3s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
        -webkit-transition-delay: 0.4s;
                transition-delay: 0.4s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
        -webkit-transition-delay: 0.5s;
                transition-delay: 0.5s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
        -webkit-transition-delay: 0.6s;
                transition-delay: 0.6s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
        -webkit-transition-delay: 0.7s;
                transition-delay: 0.7s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
        -webkit-transition-delay: 0.8s;
                transition-delay: 0.8s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
        -webkit-transition-delay: 0.9s;
                transition-delay: 0.9s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
        -webkit-transition-delay: 1s;
                transition-delay: 1s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
        -webkit-transition-delay: 1.1s;
                transition-delay: 1.1s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
        -webkit-transition-delay: 1.2s;
                transition-delay: 1.2s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
        -webkit-transition-delay: 1.3s;
                transition-delay: 1.3s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
        -webkit-transition-delay: 1.4s;
                transition-delay: 1.4s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
        -webkit-transition-delay: 1.5s;
                transition-delay: 1.5s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
        -webkit-transition-delay: 1.6s;
                transition-delay: 1.6s; }
      .blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
        -webkit-transition-delay: 1.7s;
                transition-delay: 1.7s; }
  .blog-slider__img {
    width: 200px;
    flex-shrink: 0;
    height: 200px;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
    border-radius: 20px;
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
    overflow: hidden; }
    .blog-slider__img:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
      background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
      border-radius: 20px;
      opacity: 0.8; }
    .blog-slider__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 20px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
    @media screen and (max-width: 800px) {
      .blog-slider__img {
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 90%; } }
    @media screen and (max-width: 576px) {
      .blog-slider__img {
        width: 95%; } }
    @media screen and (max-height: 500px) and (min-width: 992px) {
      .blog-slider__img {
        height: 270px; } }
  .blog-slider__content {
    padding-right: 25px; }
    @media screen and (max-width: 800px) {
      .blog-slider__content {
        margin-top: -80px;
        text-align: center;
        padding: 0 30px; } }
    @media screen and (max-width: 576px) {
      .blog-slider__content {
        padding: 0; } }
    .blog-slider__content > * {
      -webkit-transform: translateY(25px);
              transform: translateY(25px);
      -webkit-transition: all 0.4s;
      transition: all 0.4s; }
  .blog-slider__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500; }
  .blog-slider__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px; }
  .blog-slider__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em; }
  .blog-slider__button {
    display: inline-flex;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px; }
    @media screen and (max-width: 576px) {
      .blog-slider__button {
        width: 100%;
        margin-bottom: 3px; } }
  .blog-slider__button1 {
    display: inline-flex;
    margin-left: 3px;
    background-image: -webkit-linear-gradient(303deg, #8c001acc 0%, #fd3838 74%);
    background-image: linear-gradient(147deg, #8c001acc 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px; }
    @media screen and (max-width: 576px) {
      .blog-slider__button1 {
        width: 100%; } }
  .blog-slider .swiper-container-horizontal > .swiper-pagination-bullets,
  .blog-slider .swiper-pagination-custom,
  .blog-slider .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%; }
  .blog-slider__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media screen and (max-width: 800px) {
      .blog-slider__pagination {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 50% !important;
        top: 205px;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center; } }
    .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 8px 0; }
      @media screen and (max-width: 800px) {
        .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
          margin: 0 5px; } }
    .blog-slider__pagination .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      display: block;
      border-radius: 10px;
      background: #062744;
      opacity: 0.2;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      .blog-slider__pagination .swiper-pagination-bullet-active {
        opacity: 1;
        background: #fd3838;
        height: 30px;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3); }
        @media screen and (max-width: 800px) {
          .blog-slider__pagination .swiper-pagination-bullet-active {
            height: 11px;
            width: 30px; } }

.servicesAsylum {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto-fit;
  grid-gap: 20px;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 1693px) {
  .servicesAsylum {
    grid-template-columns: 1fr;
  }
}

.links {
  display: inline;
  cursor: pointer;
}

.links:hover {
  color: #8c001acc;
}

.links::after {
  content: " / ";
}

.links:last-of-type:after {
  content: "";
}

.navigation {
  align-content: left;
  grid-column: 1 / -1;
}

.servicesMain {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: auto-fit;
  grid-gap: 20px;
  margin: 120px 0 40px 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1693px) {
  .servicesMain {
    grid-template-columns: 1fr;
  }
}

.fbCard {
  margin: 10px; }
  .fbCard__content {
    color: black;
    justify-self: center;
    align-self: center;
    display: flex;
    margin: 20px; }
    .fbCard__content-text {
      width: 100%;
      justify-self: center; }

.accordion-head {
  background-color: #8c001acc !important; }

.accordion-head,
.accordion-head:hover {
  background-color: #8c001acc !important; }

.dropdown:active .fbCard__content {
  height: 100px; }

.updateBox {
  display: flex;
  flex-direction: column;
  width: 100%; }

.testimonialButtons {
  display: flex;
  width: 100%; }
  .testimonialButtons__submitButton {
    align-self: flex-end;
    width: 120px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #8c001acc;
    margin-right: 10px; }
  .testimonialButtons__checkBox {
    align-self: flex-end;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #8c001acc;
    margin-right: 10px; }

.checkboxGroup {
  display: flex;
  align-items: center; }

.dropdownInput {
  margin: 20px 0px 10px 0px;
  width: 100%;
  height: 200px; }

.login {
  display: grid;
  grid-template-rows: 10vh auto 15vh; }

.loginSection {
  display: flex;
  align-items: center;
  flex-direction: column; }

.loginBox {
  height: 400px;
  width: 300px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  margin-top: 10%; }

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  height: 60%;
  align-items: center; }

.upperLoginBackground {
  height: 40%;
  width: 100%;
  background-image: -webkit-linear-gradient(#8c001acc, #8c001acc), url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-image: linear-gradient(#8c001acc, #8c001acc), url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-blend-mode: hard-light; }

.emailInput {
  width: 200px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: inherit;
  color: inherit;
  padding: 15px 20px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .emailInput:focus {
    outline: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid green; }
  .emailInput:focus:invalid {
    border-bottom: 3px solid red; }

.pwInput {
  width: 200px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: inherit;
  color: inherit;
  padding: 15px 20px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .pwInput:focus {
    outline: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid green; }
  .pwInput:focus:invalid {
    border-bottom: 3px solid red; }

.loginButton {
  width: 200px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 30px;
  color: white;
  background-color: #8c001acc; }

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .dashboard__content {
    display: flex;
    flex-direction: column;
    margin: 20px; }
  .dashboard__loginButton {
    width: 120px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    padding: 0 30px;
    color: white;
    background-color: #8c001acc;
    grid-row: 1 /2;
    grid-column: 4 / 5;
    justify-self: end; }

.legalNewsInput {
  grid-row: 2 / 4;
  grid-column: 1 / -1;
  width: 90%;
  vertical-align: text-top; }

input::-webkit-input-placeholder {
  /* WebKit browsers */
  line-height: 1.5em; }

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  line-height: 1.5em; }

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  line-height: 1.5em; }

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  line-height: 1.5em; }

.submitButton {
  grid-row: 4 / 5;
  grid-column: 4 / 5;
  height: 30px;
  justify-self: end;
  width: 120px;
  height: 30px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0 30px;
  color: white;
  background-color: #8c001acc;
  margin-top: 10px; }

.titleInput {
  height: 30px;
  width: 40%;
  margin: 0px 10px 10px 0px; }

.urlInput {
  height: 30px;
  width: 40%;
  margin: 10px; }

.loginHeader {
  display: flex;
  align-items: center; }

.loginHeader h1 {
  margin: 10px; }

.logoutButton {
  width: 150px;
  height: 40px;
  margin: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 30px;
  color: white;
  background-color: #8c001acc; }

.legalNewsDropdown {
  margin-left: 20px;
  align-self: center; }

.legalNews {
  display: grid;
  grid-template-rows: auto;
  height: auto;
  overflow: hidden; }
  .legalNews__content {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin-top: 180px;
    margin-bottom: 24px;
    max-width: 900px;
    width: 90vw; }
    .legalNews__content--title {
      font-weight: 700;
      color: #8c001acc; }
    .legalNews__content--underline {
      height: 4px;
      background-color: #8c001acc;
      width: 400px; }
    .legalNews__content--news {
      margin: 20px;
      font-weight: 500;
      color: #8c001acc;
      display: grid;
      text-align: left; }

.newsTitleHeader {
  text-align: justify;
  vertical-align: bottom;
  padding-left: 3%;
  margin-bottom: 0%; }

.cardNews {
  overflow-y: auto; }

.newsTitle {
  padding-top: 3%;
  margin-bottom: 0%;
  font-weight: 400;
  text-align: left;
  color: #8c001acc;
  border-bottom: 2px solid #8c001acc;
  display: inline-block;
  position: relative;
  bottom: 0; }

.newsDetail {
  text-align: left; }

.dateNews {
  font-size: 12px;
  text-align: left;
  padding-left: 3%;
  color: #8c001acc;
  display: inline; }

.newsUrl {
  font-size: 12px;
  color: #8c001acc; }

.carousel .control-dots {
  margin: 0px !important; }

.carouselContainerNews {
  grid-column: 1 / -1;
  width: 90%;
  justify-content: center; }

.linkSvg {
  display: inline !important;
  height: 12px;
  width: 12px !important;
  margin-top: 6px; }

.slide {
  width: 60% !important; }

@media only screen and (max-width: 926px) {
  .carouselContainerNews {
    width: 60%; } }

@media only screen and (max-width: 800px) {
  .carouselContainerNews {
    width: 60%; } }

.testimonialsMain {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  justify-self: center; }
  .testimonialsMain__content {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin: auto;
    width: 80vw; }
    .testimonialsMain__content--title {
      font-weight: 700;
      color: #8c001acc; }
    .testimonialsMain__content--underline {
      height: 4px;
      background-color: #8c001acc;
      width: 400px; }
    .testimonialsMain__content--news {
      margin: 20px;
      font-weight: 500;
      color: #8c001acc;
      display: grid;
      object-fit: contain;
      text-align: left; }

.newsTitleHeader {
  text-align: justify;
  vertical-align: bottom;
  padding-left: 3%;
  margin-bottom: 0%; }

.newsTitle {
  padding-top: 3%;
  margin-bottom: 0%;
  font-weight: 400;
  text-align: left;
  color: #8c001acc;
  border-bottom: 2px solid #8c001acc;
  display: inline-block;
  position: relative;
  bottom: 0; }

.newsDetail {
  text-align: left; }

.dateNews {
  font-size: 12px;
  text-align: left;
  padding-left: 3%;
  color: #8c001acc;
  display: inline; }

.newsUrl {
  font-size: 12px;
  color: #8c001acc; }

.carousel .control-dots {
  margin: 0px !important; }

.cardTest {
  overflow-y: auto;
  object-fit: contain;
  margin-bottom: 8px;
  align-content: initial; }

.inner {
  padding: 0 !important; }

.carouselContainerNews {
  grid-column: 1 / -1;
  width: 90%;
  justify-content: center; }

.linkSvg {
  display: inline !important;
  height: 12px;
  width: 12px !important;
  margin-top: 6px; }

.slide {
  width: 60% !important; }

@media only screen and (max-width: 648px) {
  .testimonials-title-text {
    font-size: 24px; } }

