.testimonials {
  grid-row: 4 / 5;
  justify-content: center;
  color: #3b1c1c;
  /* background-color: rgb(255, 0, 0, 0.11); */
  height: 70vh;
  width: 80vw;
  margin: auto;
}

.testimonials-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 700;
  padding-top: 30px;
}

.testimonials-cards {
  display: grid;
  padding-bottom: 30px;
  align-content: center;
  justify-items: center;
  width: 90%;
}

.cardTest {
  /* grid-column: auto-fit;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  width: 100%; 
  text-align: center; */
}

.cardTest .inner p {
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.cardTest .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.card-1-header {
  font-size: 16px;
  color: #3b1c1c;
  font-weight: 500;
}

.card-1-paragraph {
  text-align: center;
  color: #3b1c1c;
  width: 85%;
}

.eye-svg {
  height: 60px;
  width: 70px;
  margin-bottom: 10px;
  height: 70px;
  width: 70px;
  fill: #8c001acc;
}

.quote {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-arrow.control-prev::before {
  border-right: 8px solid #8c001acc !important;
}
.control-arrow.control-next::before {
  border-left: 8px solid #8c001acc !important;
}

.slide {
  margin: 0 20%;
}

.control-arrow:hover {
  background: #fff !important;
}

.dot {
  background: #8c001acc !important;
}

@media only screen and (max-height: 738px) {
  .testimonials {
    height: auto;
  }
}

@media only screen and (max-height: 568px) {
  .testimonials {
    height: 100vh;
    margin-right: auto;
    margin-left: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
