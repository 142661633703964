.homeBar {
  height: 14vh;
  height: 9vh;
  background-color: rgba(205, 0, 51, 1);
}

.homeBar-Link {
  width: 100%;
  height: 100%;
  display: flex;
}

.homeBar-Link__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: white;
}

.homeBar-Link__content-image {
  align-self: center;
  justify-self: center;
}
.homeBar-Link__content-text {
  margin-top: -10px;
  align-self: center;
  justify-self: center;
}
