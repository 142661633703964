.profile {
  background-image: linear-gradient(#8c001acc, #8c001acc),
    url(https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.profile-title {
  margin-top: 20px;
  color: white;
  font-weight: 700;
}

.profile-content {
  height: 100%;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
}

.profile-image {
  height: 470px;
  width: 400px;
  grid-row: 2 / span 12;
  grid-column: 9 / 12;
  margin-top: 11px;
}

.profile-text {
  font-size: 20px;
  font-weight: 500;
  color: white;
  grid-row: 2 / span 10;
  grid-column: 2 / 8;
  margin-left: 40px;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .profile-text {
    font-size: 16px;
  }

  .profile-image {
    height: 400px;
    width: 330px;
    margin-top: 32px;
  }
}
@media only screen and (max-width: 1300px) {
  .profile-text {
    font-size: 14px;
  }

  .profile-image {
    height: 350px;
    width: 300px;
    margin-top: 26px;
  }
}
@media only screen and (max-width: 1000px) {
  .profile-text {
    font-size: 16px;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-content: center;
    text-align: center;
    word-wrap: normal;
    margin: 0;
    padding: 10px;
  }

  .profile-title {
    font-size: 32px;
  }

  .profile-content {
    margin: 0 auto;
  }

  .profile-image {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .profile-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .profile-text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .profile-text {
    font-size: 12px;
  }
}

@media only screen and (max-height: 568px) {
  .profile-title {
    font-size: 25px;
  }
  .profile-content {
    grid-template-columns: 0.87fr;
  }
}
