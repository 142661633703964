@media screen and (max-width: 610px) {
  .footer .centerLogo {
    justify-content: center;
  }

  #copyright {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 524px) {
  #copyright {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 396px) {
  #copyright {
    font-size: 0.55em;
  }
}
.footer {
  /* background-color: white; */
  display: grid;
  grid-template-columns: 20fr 60fr 20fr;
  height: 140px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2367001a' fill-opacity='0.15' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.contact {
  display: grid;
  grid-template-columns: 20fr 10fr 40fr 30fr;
  grid-template-rows: 20fr 14fr 14fr 14fr 14fr 20fr;
  padding-left: 2%;
}

.centerLogo {
  /* background-image: url("../images//logos/LOGO-01.png"); */
  grid-column: 2 / 2;
  display: grid;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
}

.logoTag {
  display: grid;
  background-image: url("../images//logos/LOGO-01.png");
  background-size: 100% 100%;
  font-size: 0.6em;
  font-family: "Libre Baskerville", serif;
  font-weight: normal;
  height: 100%;
  width: 100%;
  align-content: flex-end;
  color: rgba(103, 0, 26, 1);
}

#copyright {
  vertical-align: bottom;
  color: rgba(103, 0, 26, 1);
}
