.award-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgba(205, 0, 51, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%23ffffff' fill-opacity='0.36' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.award-image {
  height: 140px;
  width: 140px;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1400px) {
  .award-row {
    /* width: 50%; */
    display: grid;
    justify-content: center;
  }
  .avvoProfile {
    justify-self: center;
  }
}

@font-face {
  font-family: "Circular-Bold";
  src: url("https://assets.avvo.com/avvo-ui/0.20.5/fonts/avvo_ui/circular/circular_bold.woff")
    format("woff");
}
.avvo-badge .st0 {
  fill: #153860 !important;
}
.avvo-badge .st1 {
  fill: #010101 !important;
}
.avvo-badge .st2 {
  fill: #15467a !important;
}
.avvo-badge .st3 {
  fill: #ffffff !important;
}
.avvo-badge .st4 {
  fill: none !important;
}
.avvo-badge .st5 {
  fill: #f89838 !important;
}
.avvo-badge .st6 {
  fill: #ed514c !important;
}
.avvo-badge .st7 {
  font-family: "Circular-Bold", Avenir Next, Avenir, Century Gothic, sans-serif !important;
  font-weight: bold !important;
}
.avvo-badge .st8 {
  font-size: 20px !important;
}
.avvo-badge .st9 {
  letter-spacing: -1px !important;
}
.avvo-badge .st10 {
  font-family: "Circular-Bold", Avenir Next, Avenir, Century Gothic, sans-serif !important;
  font-size: 14px;
}
.avvo-badge .st11 {
  font-size: 65px !important;
}
.avvo-badge .st12 {
  text-anchor: middle !important;
}
.avvo-badge .st13 {
  fill: #969696 !important;
}
.avvo-badge .st14 {
  display: none !important;
}

.avvo-badge-style {
  enable-background: new 0 0 280 220;
  height: 250px;
  width: 250px;
  margin-top: 30px;
}

.st-12-style {
  font-size: 14px !important;
}

.avvoProfile {
  align-self: center;
  margin: 0 10px 0 20px;
}

.award1 {
  width: 100%;
}

@media only screen and (max-height: 568px) {
  .award-row {
    grid-template-columns: 0.87fr;
  }
}
