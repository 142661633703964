.callNow {
  color: #8c001acc;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: auto;
  position: fixed;
  left: 84vw;
  top: 3.45vh;
  z-index: 2;
}

.phoneSVG {
  height: 25px;
  width: 25px;
  text-align: center;
  margin-right: 5px;
}

.callNowNumber {
  display: flex;
  align-self: center;
  font-size: 16px;
}

.callNowText {
  font-size: 16px;
  margin-right: 5px;
  align-self: center;
  font-weight: 900;
}

@media only screen and (max-width: 1166px) {
  .callNow {
    left: 87.5vw;
  }
  .callNowNumber {
    font-size: 12px;
  }
  .phoneSVG {
    height: 17px;
    width: 17px;
  }
}
@media only screen and (max-width: 991px) {
  .callNow {
    left: 50vw;
    top: 3vh;
    font-size: 16px;
  }
  .callNowNumber {
    font-size: 16px;
  }
  .phoneSVG {
    height: 25px;
    width: 25px;
  }
}

@media only screen and (max-width: 499px) {
  .callNow {
    display: none;
  }
}
