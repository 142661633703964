.contactUs {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 20% 80%;
  max-height: 70vh;
  background-image: url("https://images.pexels.com/photos/1569012/pexels-photo-1569012.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}

.contactUs-headerPane {
  background-color: rgba(205, 0, 51, 0.9);
  display: grid;
  grid-row: 1 / -1;
  grid-template-rows: 15fr 10fr 10fr 20fr 20fr 25fr;
  grid-template-columns: 30fr 70fr;
  max-height: 70vh;
}
.contactUs-headerTxt {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  align-self: flex-end;
  /* justify-self: right; */
  margin: 0px 10px 0px 0px;
  font-size: 3.2em;
  color: white;
  font-family: "Libre Baskerville", serif;
  font-weight: Bold;
}

.contactUs-contactName {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: flex-start;
  margin: 0;
  font-size: 2em;
  color: white;
  font-family: "Libre Baskerville", serif;
  font-weight: normal;
}
.contact-areaPane {
  background-color: rgba(205, 0, 51, 0.75);
  display: grid;
  max-height: 70vh;
  align-content: center;
  padding-left: 20px;
  color: white;
  background-blend-mode: color;
  grid-row: 1 / -1;
}

.contact-areaPane__info {
  display: flex;
  flex-direction: column;
}

.contact-address__title {
  padding-bottom: 40px;
  font-weight: 350;
  font-size: 24px;
}
.contact-info__address {
  font-weight: 500;
}
.contact-info__email {
  font-weight: 500;
}
.contact-info__tel {
  font-weight: 500;
}

.bold {
  font-weight: 900;
}

.contact-form__forms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  height: 155px;
  width: 90%;
  padding-top: 20px;
}
textarea {
  resize: none;
}

.contact-form__forms-message {
  grid-column: 2 / 3;
  grid-row: 1 / span 3;
}

.contact-form__forms-submit {
  width: 90px;
  grid-column: 2 / 3;
  justify-self: end;
  background-color: rgb(66, 0, 0, 0.8);
  border: solid rgb(66, 0, 0, 1);
  cursor: pointer !important;
  color: white;
}

/*Responsive Design */
@media screen and (max-width: 1300px) {
  .contactUs-headerTxt {
    font-size: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .contactUs-headerPane {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  .contact-areaPane {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }
  .contactUs-contactName {
    display: none;
  }
  .contactUs-headerTxt {
    align-self: center;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    justify-self: center;
  }
}
