.fbCard {
  margin: 10px;

  &__content {
    color: black;
    justify-self: center;
    align-self: center;
    display: flex;
    margin: 20px;
    &-text {
      width: 100%;
      //   overflow: hidden;
      justify-self: center;
    }
  }
}

.accordion-head {
  background-color: #8c001acc !important;
}
.accordion-head,
.accordion-head:hover {
  background-color: #8c001acc !important;
}

.dropdown:active {
  .fbCard__content {
    height: 100px;
  }
}

.updateBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.testimonialButtons {
  display: flex;
  width: 100%;
  &__submitButton {
    align-self: flex-end;
    width: 120px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #8c001acc;
    margin-right: 10px;
  }
  &__checkBox {
    align-self: flex-end;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    background-color: #8c001acc;
    margin-right: 10px;
  }
}

.checkboxGroup {
  display: flex;
  align-items: center;
}

.dropdownInput {
  margin: 20px 0px 10px 0px;
  width: 100%;
  height: 200px;
}
